<template>
  <v-container class="py-0 py-sm-8 px-0 px-sm-4">
    <v-row no-gutters>
      <v-col cols="12" class="col-sm-8 white">
        <div class="px-4 pt-4 pb-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            class="rounded-0"
            placeholder="Search Branch Name ..."
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-4">
        <v-card class="d-flex align-center justify-center pa-4 fill-width fill-height primary" dark outlined @click.prevent="$role([], $store.state.user.role) ? STORE_SHOW() : null">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          Create New Branch
        </v-card>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [8, 15, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div>
              #BRANCH/{{ item.id }}
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="py-2">
              <div>
                {{ item.name }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <div>
              {{ item.phone }}
            </div>
          </template>
          <template v-slot:[`item.kecamatan_name`]="{ item }">
            <div>
              <span v-if="item.kecamatan_name">
                {{ item.kecamatan_name }}, {{ item.city_name }}
              </span>
              <span v-else class="red--text caption-small">
                belum di set
              </span>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="text-center">
              <v-chip :color="parseInt(item.status) === 100 ? 'red' : parseInt(item.status) === 99 ? 'yellow darken-2' : parseInt(item.status) === 1 ? 'green' : 'grey'" outlined dark small>
                {{ parseInt(item.status) === 100 ? 'Deleted' : parseInt(item.status) === 99 ? 'Blocked' : parseInt(item.status) === 1 ? 'Active' : 'Not Active' }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['manager'], $store.state.user.role)" depressed small dark color="orange" class="rounded-0 text-capitalize" @click.prevent="STORE_SHOW(item)">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogStore.show"
      persistent
      scrollable
      max-width="720"
    >
      <v-card v-if="dialogStore.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogStore.data.id ? 'Update Branch' : 'Create New Branch' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <!-- <div v-if="parseInt(dialogStore.data.id) === 1" class="py-2 d-flex fill-width justify-center" style="position: relative;" @click.prevent="UploadImage()">
            <v-img
              :src="dialogStore.data.welcome_banner"
              :aspect-ratio="1/1"
              class="blue lighten-4"
              style="max-width:200px;"
            />
            <div class="d-flex fill-width fill-height align-center justify-center caption c-pointer text-center" style="position: absolute;top: 0px;left:0px;">
              <span v-if="loadingImageUpload">
                uploading ...
              </span>
              <span v-else>
                {{ dialogStore.data.welcome_banner ? 'Change Welcome Banner' : 'Upload Welcome Banner' }}
              </span>
            </div>
          </div> -->
          <v-text-field
            v-model="dialogStore.data.name"
            outlined
            dense
            hide-details
            label="Nama"
            class="mb-4 rounded-0"
          />
          <v-text-field
            v-model="dialogStore.data.phone"
            outlined
            dense
            hide-details
            label="No.HP / Whatsapp"
            class="mb-4 rounded-0"
            @keypress="$NumOnly"
          />
          <!-- <v-text-field
            v-model="dialogStore.data.instagram"
            outlined
            dense
            hide-details
            label="Instagram Username"
            class="mb-4 rounded-0"
            prefix="@"
          /> -->
          <v-autocomplete
            v-model.number="dialogStore.data.kecamatan_id"
            :name="Math.random()"
            :search-input.sync="areaquery"
            :items="areas"
            item-value="kecamatan_id"
            item-text="area_name"
            outlined
            dense
            hide-details
            label="Area (Kecamatan, Kota, Provinsi)"
            class="mb-4"
            autocomplete="disabled"
          />
          <v-text-field
            v-model="dialogStore.data.ongkir_global"
            outlined
            dense
            hide-details
            label="Ongkos Kirim"
            class="mb-4 rounded-0"
            prefix="Rp"
            @keypress="$NumOnly"
          />
          <v-textarea
            v-model="dialogStore.data.description"
            outlined
            dense
            hide-details
            label="Hubungi Halofudi Team"
            class="mb-4 rounded-0"
          />
          <v-textarea
            v-model="dialogStore.data.faq"
            outlined
            dense
            hide-details
            label="Bantuan"
            class="mb-4 rounded-0"
          />
          <v-textarea
            v-model="dialogStore.data.tnc"
            outlined
            dense
            hide-details
            label="Syarat dan Ketentuan"
            class="mb-4 rounded-0"
          />
          <v-textarea
            v-model="dialogStore.data.pp"
            outlined
            dense
            hide-details
            label="Kebijakan Privasi"
            class="mb-4 rounded-0"
          />
          <v-select
            v-model.number="dialogStore.data.status"
            :items="[
              { name: 'Active', value: 1 },
              { name: 'Not Active', value: 0 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Store Status"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogStore.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="STORE_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
let searchInterval = null
let searchAreaTimeout = null
export default {
  name: 'StoreManager',
  data: () => ({
    headers: [
      {
        text: 'BRANCH ID',
        value: 'id',
        sortable: false
      },
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Phone', value: 'phone', sortable: false },
      { text: 'Area', value: 'kecamatan_name', sortable: false },
      { text: 'Status', value: 'status', align: 'center', sortable: false },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: null,
    options: {},
    dialogStore: {
      show: false,
      data: null
    },
    areaquery: '',
    areas: [],
    loadingImageUpload: false
  }),
  computed: {
    ...mapState({
      storeActive: state => state.storeActive
    })
  },
  watch: {
    areaquery (v) {
      if (!v) {
        return
      }
      const findSelectedArea = this.areas.find(r => r.area_name === v)
      if (findSelectedArea) {
        return
      }
      if (searchAreaTimeout) {
        clearTimeout(searchAreaTimeout)
      }
      searchAreaTimeout = setTimeout(() => {
        this.areaGet('?q=' + v)
        clearTimeout(searchAreaTimeout)
      }, 300)
    },
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.STORE_GET(q)
      }, 300)
    },
    tableSearchStatus () {
      this.STORE_GET(this.tableSearch)
    },
    storeActive (v) {
      if (v) {
        this.STORE_GET(this.tableSearch)
      }
    }
  },
  mounted () {
    this.STORE_GET()
  },
  methods: {
    areaGet (q, id) {
      this.$store.dispatch('AREA_GET', q + (parseInt(id) ? '&id=' + id : '')).then((res) => {
        if (res.status) {
          this.areas = res.data.data || []
        }
      })
    },
    STORE_SHOW (store) {
      if (store) {
        this.dialogStore.data = Object.assign({}, store)
        if (store.kecamatan_name) {
          this.areaquery = store.kecamatan_name
        }
      } else {
        this.dialogStore.data = {
          id: null,
          name: '',
          phone: '',
          instagram: '',
          description: '',
          faq: '',
          tnc: '',
          pp: '',
          address: '',
          kecamatan_id: 0,
          ongkir_global: 5000,
          status: 0
        }
      }
      this.dialogStore.show = true
    },
    STORE_GET (q) {
      const status = this.tableSearchStatus === 0 || this.tableSearchStatus > 0 ? this.tableSearchStatus : ''
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('STORE', query + (q ? ('&q=' + q) : '') + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '')).then((res) => {
        if (res.status) {
          if (this.$store.state.user.role !== 'root' && this.$store.state.user.role !== 'admin') {
            this.table = res.data.data.filter(r => parseInt(r.id) === parseInt(this.$store.state.user.store))
          } else {
            this.table = res.data.data
          }
          this.tableTotal = res.data.data.length || 0
        } else {
          this.table = []
          this.tableTotal = 0
        }
        this.tableLoading = false
      })
    },
    STORE_PROCESS () {
      const processedStore = Object.assign({}, this.dialogStore.data)
      if (!this.$validateName(processedStore.name)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan nama yang valid!' })
        return false
      }
      if (!processedStore.description) {
        this.$store.dispatch('TOAST', { show: true, message: 'Deskripsi tidak valid!' })
        return false
      }
      if (!this.$validatePhone(processedStore.phone)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No Telepon tidak valid!' })
        return false
      }
      processedStore.phone = this.$reformatPhone(processedStore.phone)
      this.$store.dispatch('STORE_PROCESS', processedStore)
        .then((res) => {
          if (res.status) {
            this.STORE_GET(this.tableSearch)
            this.dialogStore.show = false
            this.$store.dispatch('TOAST', { show: true, message: processedStore.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    UploadImage () {
      if (!this.loadingImageUpload) {
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e)
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event) {
      /* eslint-disable */
      const $ = this
      await $.$filetoBase64(event, async function (res) {
        if (res.status) {
          $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: 'banner'
          }).then((resUpload) => {
            console.log(resUpload)
            if (resUpload.status) {
              if (resUpload.data.data.res) {
                $.dialogStore.data.welcome_banner = resUpload.data.data.secure_url
              } else {
                $.$store.dispatch('TOAST', { show: true, message: 'Gagal upload, silahkan coba lagi!' })
              }
            }
            $.loadingImageUpload = false
          })
        } else {
          res.data === 'file_size'
            ? $.$store.dispatch('TOAST', { show: true, message: 'Image size no more than 1Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>
